<template>
  <div class="boxpane roleManagement">
    <Init @goDetail="goDetail" v-show="!showDetail" ref="commerce"></Init>
    <!-- <el-drawer
      size="50%"
      :wrapperClosable="false"
      title="对接客户详情"
      :visible.sync="visibleDetail"
    > -->
    <div v-show="showDetail">
      <el-row :gutter="6" style="margin-bottom: 0">
        <el-col :span="5" :xl="5" :md="8" :sm="12" :xs="24" class="mb20">
          商务名称：{{ comerceObj.name }}
        </el-col>
        <el-col :xs="12" :span="4" :sm="12" :md="12" class="mb20"
          >商务ID：{{ comerceObj.id }}</el-col
        >
      </el-row>
      <el-row :gutter="6" style="margin-bottom: 0">
        <el-col :span="5" :xl="5" :md="8" :sm="12" :xs="24" class="mb20">
          开发者ID：
          <el-input
            style="width: 220px"
            v-model="searchData.developerUserId"
            placeholder="请输入开发者ID"
          ></el-input
        ></el-col>
        <el-col :xs="12" :span="4" :sm="12" :md="12" class="mb20">
          开发者名称：
          <el-input
            style="width: 220px"
            v-model="searchData.companyName"
            placeholder="请输入开发者名称"
          ></el-input>
          <!-- <el-select
            style="width: 200px;margin-right: 50px;"
            clearable
            filterable
            v-model="searchData.status"
            placeholder="状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.key"
              :value="item.key"
              :label="item.value"
            ></el-option>
          </el-select> -->
          <el-button
            class="ml10 mr10"
            type="primary"
            icon="el-icon-search"
            @click="handleSearch()"
            >查询</el-button
          >
          <el-button
            class="mr10"
            type="primary"
            icon="el-icon-plus"
            @click="handleOperate('create')"
            >批量迁移</el-button
          >
          <el-button type="primary" @click="showDetail = false"
            >返回上一级</el-button
          >
        </el-col>
      </el-row>
      <PageTable
        :columns="columns"
        :tableData="tableData"
        @page-change="handleChangePage"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          slot="prefix"
          type="selection"
          width="50"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              class="operationBtn"
              @click="handleOperate('edit', row)"
              >客户迁移</el-button
            >
          </template>
        </el-table-column>
      </PageTable>
      <el-drawer
        size="50%"
        :wrapperClosable="false"
        title="客户迁移"
        :visible.sync="visible"
      >
        <el-form
          :model="editData"
          :rules="rules"
          ref="formRef"
          label-width="140px"
          class="detail-form"
        >
          <el-form-item label="原商务对接人" prop="name">
            {{ comerceObj.name }}
          </el-form-item>
          <el-form-item label="迁移客户" prop="name">
            {{ editData.comName }}
            <!-- <i v-if="showBtn" @click="handleUpdateStyle"> {{ unflod ? '展开更多>>>' : '收起' }}</i> -->
            <i
              v-if="selectionIdList.length > 10"
              @click="handleUpdateStyle"
              class="ml10 pointer colorBlue"
            >
              {{ unflod ? "展开更多>>>" : "收起" }}</i
            >
          </el-form-item>
          <el-form-item label="新商务对接人" prop="commun">
            <el-select
              style="width: 350px"
              v-model="editData.commun"
              placeholder="请选择新商务对接人"
            >
              <el-option
                v-for="item in communList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="submitForm('formRef')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
        <!-- <managePeople
        :detailData="detailData"
        v-if="visible && operationType === 'managePeople'"
        @cancel="cancelDetail"
      >
      </managePeople>
      <Detail
        v-if="visible && operationType !== 'managePeople'"
        :operationType="operationType"
        :detailData="detailData"
        :allMenuList="allMenuList"
        :allLeafList="allLeafList"
        :defaultCheckedList="defaultCheckedList"
        @submit="handleSubmit"
        @cancel="cancelDetail"
      /> -->
      </el-drawer>
    </div>
    <!-- </el-drawer>  -->
  </div>
</template>
<script>
// import Detail from "./Detail";
// import managePeople from "./managePeople";
import PageTable from "@/components/PageTableSort";
import {
  getBusinessManage,
  getBusinessDetails,
  // getRoleList,
  deleteRole,
  addRole,
  updateRole,
  customerMove,
} from "./indexServe";
import Init from "./init.vue";
export default {
  name: "roleManagement",
  // components: { Detail, Init, managePeople },
  components: { Init, PageTable },
  created() {
    // this.init();
    getBusinessManage({ page: 1, size: 3000 }).then((res) => {
      if (res.code == 0) {
        this.communList = res.data.list;
      }
    });
  },
  data() {
    return {
      visibleDetail: false,
      showDetail: false,
      comerceObj: { name: "", id: "" },
      editData: { commun: null },
      // isFresh:false,//迁移后刷新商务列表数据
      searchData: {
        developerUserId: "",
        companyName: "",
        // status:'',
        businessId: null,
      },
      // statusList: [
      //   { key: 602, value: "待预约", tag: "info" },
      //   { key: 603, value: "预约进行中", tag: "success" },
      //   { key: 604, value: "预约取消", tag: "danger" },
      //   { key: 605, value: "已上架", tag: "info" },
      //   { key: 606, value: "超预约时效", tag: "danger" },
      //   { key: 607, value: "即将上线", tag: "success" },
      //   // { key: 608, value: "审核不通过", tag: "danger" },
      // ],
      tableData: {
        list: [],
      },
      columns: [
        { key: "id", label: "开发者ID", align: "center" },
        { key: "companyName", label: "开发者名称", align: "center" },
        { key: "oldBusinessName", label: "上任商务", align: "center" },
      ],
      visible: false,
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      // detailData: {},
      allMenuList: [], // 系统中所有菜单
      allLeafList: [], // 菜单所有叶子节点
      defaultCheckedList: [],
      rules: {
        commun: [
          { required: true, message: "请选择对接人", trigger: "change" },
        ],
      },
      communList: [{ id: 1, name: "对接人1" }],
      showBtn: true,
      unflod: false,
      selectdeveloperUserIds: [], //所有已选的开发者id列表
      selectionIdList: [], //所有已选的开发者名称列表
    };
  },
  methods: {
    goDetail(row) {
      this.comerceObj = { ...row };
      this.searchData.businessId = row.id;
      this.showDetail = true;
      this.init();
    },
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleOperate(operationType, row) {
      if (operationType === "create") {
        if (this.selectionIdList.length == 0) {
          this.$message.error("请勾选需要操作的数据");
          return;
        }
        if (this.selectionIdList.length > 10) {
          this.editData.comName = this.selectionIdList.slice(0, 10).join("、");
          this.unflod = true;
        } else {
          this.editData.comName = this.selectionIdList.join("、");
        }
      } else {
        this.editData.comName = row.companyName;
        this.selectdeveloperUserIds = [row.id];
        // this.detailData = { ...row };
      }
      this.editData.commun = null;
      this.visible = true;
      this.$refs.formRef.resetFields();
      this.operationType = operationType;
    },
    handleDelete(row) {
      deleteRole({ roleId: Number(row.id) }).then((res) => {
        if (res && res.code == 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success("删除成功");
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      const fcn = this.operationType === "edit" ? updateRole : addRole; // 编辑和新建分别调用不同接口
      formData.permissions.forEach((item, index) => {
        formData.permissions[index] = Number(item);
      });
      let params = {
        name: formData.name,
        permissions: formData.permissions,
        remark: formData.remark,
      };
      if (this.operationType === "edit") {
        params.id = formData.id;
      }
      fcn(params).then((res) => {
        if (res && res.code == 0) {
          this.visible = false;
          let msg = this.operationType === "edit" ? "修改成功" : "新建成功";
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success(msg);
        }
      });
    },
    cancelDetail() {
      this.visible = false;
    },
    // 后端请求
    async getTableList(params, isDownload) {
      // const res = await getRoleList({
      const res = await getBusinessDetails({
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      });
      if (res && res.code == 0) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
    submitForm(formName) {
      if (this.editData.commun == this.comerceObj.id) {
        this.$message.error("新商务对接人不可与原商务对接人重复");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          customerMove({
            originalBusinessId: this.comerceObj.id,
            targetBusinessId: this.editData.commun,
            developerUserIds: this.selectdeveloperUserIds,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success("迁移成功");
              this.$refs.commerce.handleSearch();
              this.visible = false;
              this.showDetail = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleSelectionChange(selection) {
      this.selectionIdList = selection.map((i) => i.companyName);
      this.selectdeveloperUserIds = selection.map((i) => i.id);
    },
    handleUpdateStyle() {
      if (!this.unflod) {
        this.editData.comName = this.selectionIdList.slice(0, 10).join("、");
      } else {
        this.editData.comName = this.selectionIdList.join("、");
      }
      this.unflod = !this.unflod;
    },
  },
};
</script>
<style lang="scss">
.boxpane {
  .pagination-wapper {
    margin-top: 30px;
  }
}
</style>
<style scoped lang="scss">
.roleManagement {
  .operationBtn {
    padding: 0;
  }
}
</style>
